import { ComponentProps } from 'react'

import { value } from '@karakuri-ai/faq-component'

import { CategoryWidget } from '../CategoryWidget'
import { HtmlWidget } from '../HtmlWidget'
import { IframeWidget } from '../IframeWidget'
import { HtmlLayoutProps, IframeLayoutProps, LayoutProps } from '../interface'
import { NaviWidget } from '../NaviWidget'
import { TagWidget } from '../TagWidget'

export function renderTagPageWidget(
  layouts: LayoutProps[],
  props: ComponentProps<typeof TagWidget>
) {
  return layouts.map(layout => {
    switch (layout.type) {
      case 'navi': {
        const relatedCategories = props.pageContext.parentId
          ? [
              {
                id: props.pageContext.parentId,
                title: props.pageContext.parentTitle,
                sub: [{ id: props.pageContext.id }],
              },
            ]
          : []
        return (
          <NaviWidget
            key={layout.id}
            botId={value(props.data.settingYaml.botId, '')}
            breadcrumb={props.breadcrumb}
            tagCloud={props.tagCloud}
            relatedCategories={relatedCategories}
            hierarchies={props.hierarchies}
            searchWaitMs={props.data.settingYaml.waitMs?.search ?? 5000}
            topTitle={props.data.settingYaml.words?.breadcrumb?.top || 'よくあるご質問'}
            searchType={props.data.settingYaml.searchType || ''}
          />
        )
      }
      case 'tag': {
        return <TagWidget key={layout.id} {...props} />
      }
      case 'category': {
        return (
          <CategoryWidget
            key={layout.id}
            layout={{ ...layout, id: 'tag-category', type: 'category' }}
            data={props.data}
          />
        )
      }

      case 'html':
        return <HtmlWidget key={layout.id} layout={layout as HtmlLayoutProps} />
      case 'iframe':
        return <IframeWidget key={layout.id} layout={layout as IframeLayoutProps} />
      default:
        return null
    }
  })
}
