import { Typography } from '@material-ui/core'
import cx from 'clsx'
import { graphql, useStaticQuery } from 'gatsby'
import { PropsWithChildren, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { Brand } from '@karakuri-ai/faq-component'

import { SettingYaml } from '../graphql'
import { Session } from '../session'

function chooseTemplate(botId: string, template: string) {
  if (['sbineomobile', 'sbineomobile-testing', 'rarejob', '5z13fcpat1'].some(a => a === botId)) {
    return ''
  }
  return template
}
function toClassName(botId: string) {
  if ('sbineomobile-testing' === botId) {
    return 'b-sbineomobile'
  }
  return `b-${botId}`
}
interface StaticQueryProps {
  settingYaml: SettingYaml
}

interface IndexLayoutProps {
  className: string
}

const IndexLayoutQuery = graphql`
  query IndexLayoutQuery {
    settingYaml(ignore: { ne: true }) {
      botId
      title
      headerImageUrl
      headerImageAltText
      headerImageLinkUrl
      faviconUrl
      styles
      scripts
      css
      js
      header
      footer
      siteName
      template
      words {
        header {
          caption
        }
        meta {
          googleSiteVerification
          keywords
          robots
        }
      }
    }
  }
`

function IndexLayout({
  className: layoutClassName,
  children,
}: PropsWithChildren<IndexLayoutProps>) {
  const data = useStaticQuery<StaticQueryProps>(IndexLayoutQuery)
  useEffect(() => Session.init(), [])

  const styleLinks = (data.settingYaml.styles || []).filter((s): s is string => !!s)
  const headerImageLinkUrl = data.settingYaml.headerImageLinkUrl || ''
  const headerImageUrl = data.settingYaml.headerImageUrl || ''
  const headerImageAlt =
    data.settingYaml.headerImageAltText || data.settingYaml.siteName || data.settingYaml.title || ''
  const header = data.settingYaml.header || ''
  const footer = data.settingYaml.footer || ''
  const js = data.settingYaml.js || ''
  const css = data.settingYaml.css || ''
  const scripts = (data.settingYaml.scripts || []).filter((s): s is string => !!s)
  const faviconUrl = data.settingYaml.faviconUrl || ''
  const botId = data.settingYaml.botId || ''
  const templateClassName = chooseTemplate(botId, data.settingYaml.template || 'template-2')
  const className = toClassName(botId)
  const headerCaption =
    data.settingYaml.words?.header?.caption !== undefined
      ? data.settingYaml.words?.header?.caption
      : 'よくあるご質問（Q&A）'
  const meta = data.settingYaml.words?.meta || {}

  return (
    <>
      <div className={cx(layoutClassName, 'faq-layout')}>
        <Helmet
          title={data.settingYaml.title || ''}
          meta={Object.entries(meta)
            .filter((target): target is [string, string] => !!target[1])
            .map(([name, content]) => ({
              name: name.replace(/([A-Z])/g, '-$1').toLowerCase(),
              content,
            }))}
          bodyAttributes={{ class: cx('faq') } as any}
          htmlAttributes={{ class: `${className} ${templateClassName}` } as any}
        >
          {styleLinks.map(style => (
            <link rel="stylesheet" href={style} key={style} />
          ))}
          {faviconUrl &&
            ['icon', 'shortcut icon', 'apple-touch-icon'].map(rel => (
              <link key={rel} rel={rel} href={faviconUrl} />
            ))}
          {css && <style>{css}</style>} {js && <script>{js}</script>}
        </Helmet>
        {header ? (
          <header dangerouslySetInnerHTML={{ __html: header }} />
        ) : (
          <header>
            <div className="faq-layout-header">
              <h1 className="faq-logo">
                <Brand src={headerImageUrl} alt={headerImageAlt} link={headerImageLinkUrl} />
              </h1>
              <div className="faq-header-spacer" />
              {headerCaption && (
                <Typography paragraph className="faq-header-caption" variant="caption">
                  {headerCaption}
                </Typography>
              )}
            </div>
          </header>
        )}
        <div className={cx('faq-layout-container')}>{children}</div>
        <footer dangerouslySetInnerHTML={{ __html: footer }} />
      </div>
      <Helmet>
        {scripts.map(s => (
          <script src={s} key={s} />
        ))}
      </Helmet>
    </>
  )
}

export default IndexLayout
