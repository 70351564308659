import { Maybe, SettingYamlCategorySub } from '../graphql'

interface Hierarchy {
  title: string
  id: string
}
export function traverse(
  category: Maybe<SettingYamlCategorySub>,
  dictionary: Maybe<Record<string, SettingYamlCategorySub>>,
  hierarchies: Hierarchy[] = []
): Hierarchy[] {
  if (!dictionary || !category) {
    return hierarchies
  }
  const c = dictionary[category.id || '']
  if (!c || !c.parent) {
    return hierarchies
  }
  const parent = dictionary[c.parent]
  if (!parent) {
    return hierarchies
  }
  return traverse(parent, dictionary, [
    ...hierarchies,
    { id: parent.id || '', title: parent.title || '' },
  ])
}
