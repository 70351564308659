import { gql } from '@apollo/client'

import { SkillTokenParam } from '@karakuri-ai/faq-component'

export const GQL_SEARCH_FAQ = gql`
  query allFAQ($botId: String!, $inputValue: String!, $searchType: String, $count: Int = 20) {
    queryFAQ: queryFAQV2(
      query: { botId: $botId, text: $inputValue, count: $count, searchType: $searchType }
    ) {
      id
      title
    }
  }
`

interface SkillToken {
  id: string
  token: string
}
export interface SkillTokenData {
  skillToken: SkillToken
}
export interface SkillTokenDataV2 {
  skillTokenV2: SkillToken
}

export interface SkillTokenVars {
  accountId: string
  skillId: string
  session?: string
  param: SkillTokenParam
}

export const GQL_FORM_TOKEN = gql`
  query formToken(
    $accountId: String!
    $skillId: String!
    $session: String
    $param: FAQSkillInputParam!
  ) {
    skillTokenV2(
      params: { accountId: $accountId, skillId: $skillId, session: $session, param: $param }
    ) {
      id
      token
    }
  }
`

export interface PulldownEvent {
  __krkr__: boolean
  type: 'send'
  query: { text: string }
}

export function isPulldownEvent(target: unknown): target is PulldownEvent {
  const event = target as PulldownEvent
  return event.__krkr__ && event.type === 'send'
}

export interface PulldownRequest {
  accountId: string
  query: string
}

export interface PulldownResponse {
  pulldownQuery: {
    id?: string
  }
}

export const GQL_PULLDOWN = gql`
  query pulldown($accountId: String!, $query: String!) {
    pulldownQuery(params: { accountId: $accountId, query: $query }) {
      id
    }
  }
`
