import { Box, Button } from '@material-ui/core'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'
import { Link, PageProps } from 'gatsby'
import { ComponentProps, forwardRef } from 'react'

import { QAHeader, QAPanel } from '@karakuri-ai/faq-component'

import {
  CategoryDictionary,
  FilterCategoryLayoutProps,
  SearchPageContext,
  SearchParams,
} from './interface'

type Props = Pick<
  PageProps<Record<string, unknown>, SearchPageContext, SearchParams>,
  'location' | 'pageContext'
> & {
  layout: FilterCategoryLayoutProps
  dictionary: CategoryDictionary
  q?: string[]
}
export function FilterCategoryWidget({ layout, dictionary, pageContext, location, q }: Props) {
  if (!pageContext.category) {
    return null
  }

  const subCategories = pageContext.category.sub || []
  const parent = pageContext.category.parent
  const baseTitle = layout.title || 'カテゴリ一覧'
  const title = pageContext.category.title
    ? `${pageContext.category.title}の${baseTitle}`
    : baseTitle
  const hasSubcategories = subCategories.length > 0
  return (
    <QAPanel className="faq-filter-category">
      {hasSubcategories ? <QAHeader title={title} /> : <Box margin={3} />}
      {hasSubcategories && (
        <Box display="flex" flexWrap="wrap" className="faq-filter-category-sub-category-wrapper">
          {subCategories.map(c => {
            const LinkComponent = forwardRef<HTMLAnchorElement, ComponentProps<typeof Button>>(
              function LinkComponent(props, ref) {
                return (
                  <Link
                    ref={ref as any}
                    {...(props as any)}
                    to={removeDuplicate(
                      `${location.pathname === '/' ? '/search' : location.pathname}/${c?.id}`
                    )}
                    state={{ q }}
                  />
                )
              }
            )
            return (
              <Button
                key={`${c?.id}`}
                variant="outlined"
                color="secondary"
                className="faq-filter-category-sub-category"
                component={LinkComponent}
                endIcon={<ChevronRightIcon />}
              >
                {c?.title}
              </Button>
            )
          })}
        </Box>
      )}
      {parent && dictionary[parent] && (
        <Button
          startIcon={<ChevronLeftIcon />}
          color="secondary"
          component={forwardRef<HTMLAnchorElement, ComponentProps<typeof Button>>(
            function LinkComponent(props, ref) {
              return (
                <Link
                  ref={ref as any}
                  {...(props as any)}
                  to={`${location.pathname.replace(/\/[^/]+\/?$/g, '')}`}
                  state={{ q }}
                />
              )
            }
          )}
        >
          {dictionary[parent].title}
        </Button>
      )}
    </QAPanel>
  )
}
function removeDuplicate(target: string) {
  return target.replace(/\/\//g, '/')
}
