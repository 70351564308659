import { PageProps } from 'gatsby'

import { CategoryWidget } from '../CategoryWidget'
import { FaqWidget } from '../FaqWidget'
import { FilterCategoryWidget } from '../FilterCategoryWidget'
import { FilterSearchWidget } from '../FilterSearchWidget'
import { HtmlWidget } from '../HtmlWidget'
import { IconWidget } from '../IconWidget'
import { IframeWidget } from '../IframeWidget'
import { ImportantWidget } from '../ImportantWidget'
import { InformationWidget } from '../InformationWidget'
import {
  CategoryLayoutProps,
  Data,
  FilterCategoryLayoutProps,
  FilterSearchLayoutProps,
  FrequentLayoutProps,
  HtmlLayoutProps,
  IconLayoutProps,
  IframeLayoutProps,
  ImportantLayoutProps,
  InformationLayoutProps,
  LayoutProps,
  SearchLayoutProps,
  SearchParams,
} from '../interface'
import { SearchWidget } from '../SearchWidget'

export type Props = Pick<
  PageProps<Data, Record<string, unknown>, SearchParams>,
  'data' | 'location'
>

export function renderIndexPageWidget(layouts: LayoutProps[], { data, location }: Props) {
  return layouts.map(layout => {
    switch (layout.type) {
      case 'information':
        return (
          <InformationWidget
            key={layout.id}
            layout={layout as InformationLayoutProps}
            data={data}
          />
        )
      case 'important':
        return (
          <ImportantWidget key={layout.id} layout={layout as ImportantLayoutProps} data={data} />
        )
      case 'search':
        return <SearchWidget key={layout.id} layout={layout as SearchLayoutProps} data={data} />
      case 'frequent':
        return <FaqWidget key={layout.id} layout={layout as FrequentLayoutProps} data={data} />
      case 'category':
        return <CategoryWidget key={layout.id} layout={layout as CategoryLayoutProps} data={data} />
      case 'icon':
        return <IconWidget key={layout.id} layout={layout as IconLayoutProps} />
      case 'filterSearch':
        return (
          <FilterSearchWidget
            key={layout.id}
            layout={layout as FilterSearchLayoutProps}
            tags={[]}
            q={[]}
            data={data}
            location={location}
          />
        )
      case 'filterCategory':
        return (
          <FilterCategoryWidget
            key={layout.id}
            layout={layout as FilterCategoryLayoutProps}
            dictionary={{}}
            location={location}
            pageContext={{ category: { sub: data.settingYaml.categories } } as any}
          />
        )
      case 'html':
        return <HtmlWidget key={layout.id} layout={layout as HtmlLayoutProps} />
      case 'iframe':
        return <IframeWidget key={layout.id} layout={layout as IframeLayoutProps} />
      default:
        break
    }

    return null
  })
}
