import { Maybe, SettingYamlCategory, SettingYamlCategorySub } from '../graphql'

let dictionary: Record<string, SettingYamlCategorySub>
export async function loadDictionary() {
  if (!dictionary) {
    // eslint-disable-next-line compat/compat
    const categories = await fetch('/categories.json').then<Maybe<Maybe<SettingYamlCategory>[]>>(
      response => response.json()
    )

    dictionary = (categories || []).reduce<Record<string, SettingYamlCategorySub>>((acc, p) => {
      if (!p || !p.id) {
        return acc
      }
      return { ...acc, [p.id]: p }
    }, {})
  }
  return dictionary
}
