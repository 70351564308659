import { ComponentProps } from 'react'

import { value } from '@karakuri-ai/faq-component'

import { AnswerWidget } from '../AnswerWidget'
import { BreadcrumbWidget } from '../BreadcrumbWidget'
import { HtmlWidget } from '../HtmlWidget'
import { IframeWidget } from '../IframeWidget'
import { CategoryDictionary, HtmlLayoutProps, IframeLayoutProps, LayoutProps } from '../interface'
import { NaviWidget } from '../NaviWidget'
import { RelatedLayoutProps, RelatedWidget } from '../RelatedWidget'

export function renderAnswerPageWidget(
  layouts: LayoutProps[],
  props: ComponentProps<typeof AnswerWidget> & { dictionary: CategoryDictionary }
) {
  return layouts.map(layout => {
    switch (layout.type) {
      case 'navi':
        return (
          <NaviWidget
            key={layout.id}
            botId={value(props.data.settingYaml.botId, '')}
            breadcrumb={props.breadcrumb}
            tagCloud={!!props.data.settingYaml.tagCloud}
            relatedCategories={props.pageContext.relatedCategories}
            hierarchies={props.location.state?.hierarchies || []}
            searchWaitMs={props.data.settingYaml.waitMs?.search ?? 5000}
            topTitle={props.data.settingYaml.words?.breadcrumb?.top || 'よくあるご質問'}
            searchType={props.data.settingYaml.searchType || ''}
          />
        )
      case 'answer':
        return <AnswerWidget key={layout.id} {...props} />
      case 'breadcrumb':
        return (
          <BreadcrumbWidget
            key={layout.id}
            breadcrumb={props.breadcrumb}
            tagCloud={!!props.data.settingYaml.tagCloud}
            relatedCategories={props.pageContext.relatedCategories}
            hierarchies={props.location.state?.hierarchies || []}
            dictionary={props.dictionary}
            topTitle={props.data.settingYaml.words?.breadcrumb?.top || 'よくあるご質問'}
          />
        )
      case 'related':
        return (
          <RelatedWidget
            key={layout.id}
            related={props.pageContext?.related || []}
            layout={layout as RelatedLayoutProps}
          />
        )
      case 'html':
        return <HtmlWidget key={layout.id} layout={layout as HtmlLayoutProps} />
      case 'iframe':
        return <IframeWidget key={layout.id} layout={layout as IframeLayoutProps} />
      default:
        return null
    }
  })
}
