import { GatsbyLinkProps, Link } from 'gatsby'
import { forwardRef } from 'react'

import { QAContent, QAHeader, QAPanel, values } from '@karakuri-ai/faq-component'

import { Data, InformationLayoutProps } from './interface'

interface Props {
  data: Data
  layout: InformationLayoutProps
}
export function InformationWidget({ layout, data }: Props) {
  const informations = values(data.settingYaml.informations, [])
  if (!informations || informations.length === 0) {
    return null
  }
  return (
    <QAPanel className="faq-panel-information">
      <QAHeader title={`${layout.title || 'お知らせ'}`} />
      {informations.map(({ id, title }) => {
        if (!id || !title) {
          return null
        }
        const LinkComponent = forwardRef<
          HTMLAnchorElement,
          GatsbyLinkProps<Record<string, unknown>>
        >(function LinkComponent(props, ref) {
          return <Link ref={ref as any} {...props} to={`answer/${id}`} />
        })
        return <QAContent key={id} question={title} LinkComponent={LinkComponent} info />
      })}
    </QAPanel>
  )
}
