import { GatsbyLinkProps, Link } from 'gatsby'
import { forwardRef } from 'react'

import { QAContent, QAHeader, QAPanel, values } from '@karakuri-ai/faq-component'

import { Data, ImportantLayoutProps } from './interface'

interface Props {
  data: Data
  layout: ImportantLayoutProps
}
export function ImportantWidget({ layout, data }: Props) {
  const importants = values(data.settingYaml.importants, [])
  if (!importants || importants.length === 0) {
    return null
  }
  return (
    <QAPanel className="faq-panel-important">
      <QAHeader title={`${layout.title || '重要なご質問'}`} />
      {importants.map(({ id, title }) => {
        if (!id || !title) {
          return null
        }
        const LinkComponent = forwardRef<
          HTMLAnchorElement,
          GatsbyLinkProps<Record<string, unknown>>
        >(function LinkComponent(props, ref) {
          return <Link ref={ref as any} {...props} to={`/answer/${id}?important=true`} />
        })
        return <QAContent key={id} question={title} LinkComponent={LinkComponent} />
      })}
    </QAPanel>
  )
}
